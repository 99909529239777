export const FilterIcon = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76248 0.5C4.28394 0.5 3.81976 0.66248 3.44521 0.959719C3.07071 1.25745 2.80766 1.67308 2.69916 2.13874H1.14614C0.877143 2.13874 0.65918 2.35672 0.65918 2.6257C0.65918 2.89468 0.877156 3.11317 1.14614 3.11317H2.69916C2.84729 3.73686 3.26936 4.25999 3.84797 4.53646C4.42608 4.81288 5.09833 4.81288 5.67695 4.53646C6.25507 4.26003 6.67713 3.7369 6.82526 3.11317H12.8541C13.1231 3.11317 13.3411 2.8947 13.3411 2.6257C13.3411 2.35671 13.1231 2.13874 12.8541 2.13874H6.82526C6.71677 1.67308 6.45372 1.25745 6.07921 0.959719C5.7047 0.66248 5.24055 0.5 4.76248 0.5ZM4.76248 3.77752C4.29881 3.77752 3.8807 3.49862 3.70334 3.07011C3.526 2.6421 3.62408 2.14919 3.95202 1.82124C4.27947 1.4933 4.77288 1.3952 5.2009 1.57256C5.62941 1.7499 5.90831 2.16801 5.90831 2.6317C5.90831 2.93537 5.78793 3.22715 5.57293 3.44215C5.35794 3.65714 5.06615 3.77752 4.76248 3.77752Z"
        fill="#003A8C"
      />
      <path
        d="M9.25517 5.25622C8.77663 5.25672 8.31294 5.4187 7.93841 5.71644C7.56341 6.01367 7.30085 6.4293 7.19236 6.89496H1.14614C0.877143 6.89496 0.65918 7.11344 0.65918 7.38243C0.65918 7.65143 0.877156 7.86939 1.14614 7.86939H7.17499C7.32311 8.49309 7.74518 9.01621 8.32329 9.29268C8.90191 9.56911 9.57415 9.56911 10.1523 9.29268C10.7309 9.01625 11.153 8.49312 11.3011 7.86939H12.8541C13.1231 7.86939 13.3411 7.65141 13.3411 7.38243C13.3411 7.11345 13.1231 6.89496 12.8541 6.89496H11.3011C11.1931 6.43228 10.9325 6.01913 10.5615 5.72189C10.1904 5.42465 9.73077 5.26069 9.25517 5.25622ZM9.25517 8.52283C8.7915 8.52283 8.37388 8.24343 8.19653 7.81542C8.01869 7.38691 8.11677 6.894 8.44471 6.56605C8.77265 6.2386 9.26557 6.14051 9.69359 6.31786C10.1221 6.4952 10.4015 6.91282 10.4015 7.37651C10.4015 7.68067 10.2806 7.97196 10.0656 8.18696C9.85063 8.40195 9.55934 8.52283 9.25517 8.52283Z"
        fill="#003A8C"
      />
    </svg>
  );
};
